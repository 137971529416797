import {
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Progress,
  Spinner,
  Text,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import React, {
  useCallback, useEffect,
  useState,
} from 'react';

import pLimit from 'p-limit';

import SelectImages from '../admin/profile/components/SelectImages';
import {DrivePhotoView} from '../../components/image/DrivePhotoView';
import {delPhoto, uploadPhoto} from '../../api/projects-api';
import Bugsnag from '@bugsnag/js';


interface DrivePhotoMeta {
  name: string;
  id: string;
  thumbnailLink: string;
}

interface LocalPhotoMeta {
  id: string;
  i: File;
  uploading: boolean;
  finished: boolean;
}

interface DrivePhotoProps {
  image: DrivePhotoMeta;
  onDelete?: () => void;
  restore?: () => void;
  variant?: 'add'|'delete'|'';
}

interface ManagePicturesProps {
  folderList: DrivePhotoMeta[];
  deleting: DrivePhotoMeta[],
  setDeletingFiles: (files: DrivePhotoMeta[]) => void,
  adding: LocalPhotoMeta[],
  setAddingFiles: (files: (prevAdding: LocalPhotoMeta[]) => LocalPhotoMeta[]) => void,
}

export const ManagePictures: React.FC<ManagePicturesProps> = (
  {
    folderList,
    deleting,
    setDeletingFiles,
    adding,
    setAddingFiles,
  },
) => {
  const image2Persist = [
    ...(folderList || []),
    ...adding,
  ];

  return <Flex flexDir='column' w='100%'>
    <SelectImages
      overflowY='auto'
      maxH='600px'
      registerNewFiles={
        (newItems: File[]) => setAddingFiles(
          (prevAdding: LocalPhotoMeta[]) => [
            ...prevAdding,
            ...(
              newItems
                .filter((f) => !prevAdding.find((p) => p.id === 'pel' + f.name))
                .map(
                  (i) => ({
                    id: 'pel' + i.name,
                    i,
                    uploading: false,
                    finished: false,
                  }),
                )
            ),
          ],
        )
      }
    />

    {
      deleting.length > 0
      && <Flex
        overflowY='auto'
        bg='white'
        borderRadius='20px'
        p='10px' m='20px' w='100%'
        maxH="600px"
        direction='column'
      >
        <Text fontWeight='bold'>Photos to be deleted:</Text>
        <Wrap
          direction='row'
          py="20px"
          spacing={10}
        >
          {
            deleting.map(
              (image) => <WrapItem key={image.id}>
                <DrivePhoto
                  image={image}
                  restore={() => setDeletingFiles(deleting.filter((ai) => ai.id !== image.id))}
                  variant='delete'
                />
              </WrapItem>,
            )
          }
        </Wrap>
      </Flex>
    }
    {
      image2Persist.length > 0
      && <Flex
        overflowY='auto'
        bg='white'
        borderRadius='20px'
        p='10px' m='20px' w='100%'
        maxH="600px"
        direction='column'
      >
        <Text fontWeight='bold'>Photos will be preserved:</Text>
        <Wrap
          direction='row'
          py="20px"
          spacing={10}
        >
          {
            image2Persist.length > 0
            && image2Persist.filter(
              (image: DrivePhotoMeta|LocalPhotoMeta) => !deleting.find(
                (i: DrivePhotoMeta|LocalPhotoMeta) => i.id === image.id,
              ),
            ).map(
              (image: DrivePhotoMeta | LocalPhotoMeta) => <WrapItem key={image.id}>
                <DrivePhoto
                  image={
                    'i' in image
                      ? {
                        name: image.i.name,
                        thumbnailLink: URL.createObjectURL(image.i),
                        id: image.id,
                      } as DrivePhotoMeta
                      : image as DrivePhotoMeta
                  }
                  onDelete={
                    () => {
                      if (adding.find((i: LocalPhotoMeta) => image.id === i.id)) {
                        setAddingFiles(() => adding.filter((ai) => ai.id !== image.id));
                      } else {
                        const sureDrivePhoto = image as DrivePhotoMeta;
                        setDeletingFiles([...deleting, sureDrivePhoto]);
                      }
                    }
                  }
                  variant={adding.find((i: LocalPhotoMeta) => image.id === i.id) && 'add' || ''}
                />
              </WrapItem>,
            )
          }
        </Wrap>
      </Flex>
    }
  </Flex>;
};

const DrivePhoto: React.FC<DrivePhotoProps> = ({image, onDelete, restore, variant}) => <Popover>
  <PopoverTrigger>
    <IconButton
      aria-label={`Photo: ${image.name}`}
      h='220px'
      p='10px'
      bg={variant === 'add' ? 'lightgreen' : variant === 'delete' ? 'lightcoral' : ''}
      icon={<DrivePhotoView th={image.thumbnailLink} name={image.name} />}
    />
  </PopoverTrigger>
  <PopoverContent>
    <PopoverArrow/>
    <PopoverHeader>Picture info</PopoverHeader>
    <PopoverCloseButton/>
    <PopoverBody>
      <Text>File name:</Text><Text fontWeight={700}>{image.name}</Text>
      {
        onDelete
        && <Button colorScheme='red' onClick={() => onDelete()}>Delete</Button>
      }
      {
        restore
        && <Button colorScheme='green' onClick={() => restore()}>Restore</Button>
      }
    </PopoverBody>
  </PopoverContent>
</Popover>;

interface ManagePicturesModalProps {
  isOpenManagePictures: boolean,
  onCloseManagePictures: () => void,
  getReadyReportInProgress: boolean,
  readyReport: {editedPicNumbers: number},
  projectId: string,
  updateProjectPicturesInProgress: boolean,
  handleFinishManagePictures: () => void,
  // eslint-disable-next-line
  useGetFolderList: Function,
}

export const ManagePicturesModal: React.FC<ManagePicturesModalProps> = (
  {
    isOpenManagePictures,
    onCloseManagePictures,
    getReadyReportInProgress,
    readyReport,
    projectId,
    updateProjectPicturesInProgress,
    handleFinishManagePictures,
    useGetFolderList,
  },
) => {
  const toast = useToast();
  const [finishing, setFinishing] = useState(false);
  const [uploaded, setUploaded] = useState(0);
  const [deleting, setDeletingFiles] = useState<DrivePhotoMeta[]>([]);
  const [adding, setAddingFiles] = useState<LocalPhotoMeta[]>([]);

  const [
    {
      data: folderList,
      loading: getFolderListInProgress,
    },
    getFolderList,
  ] = useGetFolderList(projectId);

  const onFinishing = useCallback(
    async () => {
      if (finishing) {
        return;
      }

      setFinishing(true);
      const limit = pLimit(3);

      if (adding.length > 0 || deleting.length > 0) {
        const uploadIncrement = Math.ceil(100 / (adding.length + deleting.length));

        await Promise.all(
          [
            ...adding.map(
              (p: LocalPhotoMeta) => limit(
                () => uploadPhoto(
                  p.i,
                  projectId,
                  (p: number) => setUploaded((pu) => Math.min(100, Math.ceil(pu + uploadIncrement * p / 100))),
                  0.1,
                )[0],
              ),
            ),
            ...deleting.map(
              (p: DrivePhotoMeta) => limit(
                () => delPhoto(projectId, p.id)
                  .then(() => setUploaded((pu) => Math.min(100, pu + uploadIncrement))),
              ),
            ),
          ],
        ).catch(
          (e) => {
            Bugsnag.notify(e);
            toast({
              title: 'Error during uploading photos. Please contact us.',
              status: 'error',
              duration: null,
              isClosable: true,
            });
          },
        );
      }

      setDeletingFiles([]);
      setAddingFiles([]);
      setUploaded(0);
      getFolderList();
      setFinishing(false);
    },
    [setFinishing, finishing, adding, deleting, projectId],
  );

  useEffect(
    () => {
      if (isOpenManagePictures) {
        getFolderList();
      }
    },
    [isOpenManagePictures],
  );

  const closeManagePicture = useCallback(
    () => {
      setDeletingFiles([]);
      setAddingFiles([]);
      setUploaded(0);
      onCloseManagePictures();
    },
    [],
  );

  return <Modal isOpen={isOpenManagePictures} onClose={closeManagePicture}>
    <ModalOverlay/>
    <ModalContent minW='60vw' maxW={{base: '80vw', sm: '90vw'}} overflow='auto'>
      <ModalHeader>Manage pictures {getReadyReportInProgress && <Spinner/>}</ModalHeader>
      <ModalCloseButton/>
      {
        readyReport
        && <ModalBody>
          <Text>
            You've uploaded {readyReport.editedPicNumbers} images.
          </Text>

          {
            getFolderListInProgress
            && <Spinner />
          }
          <ManagePictures
            folderList={folderList}
            adding={adding}
            setAddingFiles={setAddingFiles}
            deleting={deleting}
            setDeletingFiles={setDeletingFiles}
          />

        </ModalBody>
      }
      <ModalFooter>
        <Flex flexDir='column' w='100%'>
          {
            finishing
            && <Progress hasStripe value={uploaded} width='100%' my='1em' />
          }
          <Flex flexDir='row' justifyContent='flex-end' w='100%'>
            <Button
              variant='photonMedium'
              mr={3}
              onClick={closeManagePicture}
            >
              Back
            </Button>
            <Button
              width='fit-content'
              isLoading={finishing || updateProjectPicturesInProgress}
              variant='photonDark'
              onClick={
                async () => {
                  if (finishing) {
                    return;
                  }
                  setFinishing(true);

                  await onFinishing();
                  handleFinishManagePictures();

                  setFinishing(false);
                }
              }
            >
              Finish Editing Images
            </Button>
          </Flex>
        </Flex>
      </ModalFooter>
    </ModalContent>
  </Modal>;
};
