import React from 'react';
import {
  Flex,
  Image,
  Spinner,
} from '@chakra-ui/react';

import {FilePlaceholder} from '../../assets/img/resources/FilePlaceholder';


interface DrivePhotoViewProps {
  th: string,
  name: string,
}

export const DrivePhotoView: React.FC<DrivePhotoViewProps> = ({th, name}) => <>
  {
    th
    && <Image
      fallback={
        <Flex alignItems='center' justifyContent='center' h='200px' w='200px'>
          <Spinner/>
        </Flex>
      }
      alt={name}
      boxSize='200px'
      fit='cover'
      src={th}
    />
  }
  {
    !th
    && <FilePlaceholder style={{height: '200px'}}/>
  }
</>;
