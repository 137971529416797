import {
  Text,
  Flex,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Checkbox,
} from '@chakra-ui/react';
import {Dispatch, SetStateAction} from 'react';

type Criteria = {
    _id: string,
    name: string
}

export default function QualityControlModal(props: {
  selectedCriterias: string[],
  setSelectedCriterias: Dispatch<SetStateAction<string[]>>
  isOpen: boolean;
  onClose: (successfulDelete?: boolean) => void;
  handleFinishEditing: () => void;
  qualityCriterias: Criteria[]
}) {
  const {
    isOpen,
    onClose,
    qualityCriterias,
    selectedCriterias,
    setSelectedCriterias,
    handleFinishEditing,
  } = props;

  const handleQualityControlChange = (checked: boolean, criteriaId: string) => {
    setSelectedCriterias(
      (prev) => checked
        ? [...prev, criteriaId]
        : prev.filter((item) => item !== criteriaId));
  };
  const hamdleConfirm = () => {
    handleFinishEditing();
    onClose();
  };


  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text align='center'>Quality report</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text
            align='center'
            fontSize='20px'
          >
            Please check the boxes below if you have you experienced any issues with the submitted images!
          </Text>
          <Flex
            direction='column'
            w='100%'
            my='30px'
            alignItems='center'
          >
            <Flex direction='column'>
              {
                qualityCriterias?.map(
                  (criteria: Criteria) => (
                    <Checkbox
                      m="5px"
                      value={criteria._id}
                      key={criteria._id}
                      onChange={(e) => handleQualityControlChange(e.target.checked, criteria._id)}
                      isChecked={selectedCriterias?.some((criteriaId)=> criteriaId === criteria._id)}
                      fontSize='600px'
                    >
                      <Text fontSize='20px'>{criteria.name}</Text>
                    </Checkbox>
                  ),
                )
              }
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex w='100%' justifyContent='center'>
            <Button
              mr='5px'
              w='140px'
              variant='photonMedium'
              onClick={() => onClose()}
            >
              Back
            </Button>
            <Button
              w='140px'
              variant='photonDark'
              onClick={hamdleConfirm}
            >
              Confirm
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}


