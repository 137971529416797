import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
  Wrap,
  WrapItem,
  Divider,
} from '@chakra-ui/react';
import {
  RefFile,
  useFinishEditing,
  useGetFolderList,
  useGetProject,
  useGetReadyReport,
  useUpdateProjectPictures,
} from 'api/projects-api';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import moment from 'moment';
import Card from 'components/card/Card';
import {
  AiOutlinePhone,
  AiOutlineUser,
} from 'react-icons/ai';
import {MdAlternateEmail} from 'react-icons/md';
import {PiCursor} from 'react-icons/pi';
import {ArrowForwardIcon, ArrowBackIcon} from '@chakra-ui/icons';

import {
  ManualEditingStatus,
  ProjectStatus,
  translateProjectStatus,
} from '../../constants';
import {ManagePicturesModal} from './ManagePicturesModal';
import {DrivePhotoView} from '../../components/image/DrivePhotoView';
import {SpinnerCurtains} from '../../components/loader/SpinnerCurtains';
import Bugsnag from '@bugsnag/js';
import {useAuth} from '../../hooks/useAuth';
import QualityControlModal from './QualityControlModal';


const FinalImagesList = (props: { projectId: string }) => {
  const [
    {
      data: finalFolderList,
      loading: getFinalFolderListInProgress,
      error: getFinalFolderListError,
    },
  ] = useGetFolderList(props.projectId);

  return (
    <Flex
      flexGrow={1}
      h='fit-content'
      overflowY='auto'
      bg='white'
      borderRadius='20px'
      p='10px' m='20px' w='100%'
      maxH='300px'
    >
      {
        getFinalFolderListInProgress
        && <Spinner/>
      }
      {
        getFinalFolderListError
        && <Text color='red.300' fontWeight='bold'>
          {getFinalFolderListError.response?.data?.error_message ?? 'An error is occurred during get file list.'}
        </Text>
      }
      {
        finalFolderList?.length > 0
        && <Wrap
          direction={{base: 'column', sm: 'row'}}
          justify='center'
          py='20px'
          spacing={5}
        >
          {
            finalFolderList.map(
              (image: { name: string, id: string, thumbnailLink: string }) => <WrapItem key={image.id}>
                <DrivePhotoView th={image.thumbnailLink} name={image.name}/>
              </WrapItem>,
            )
          }
        </Wrap>
      }
    </Flex>
  );
};

export default function ProjectDetailsPage() {
  const {user} = useAuth();
  const userId = user?.userId;

  const {projectId} = useParams();
  const navigate = useNavigate();
  const [selectedCriterias, setSelectedCriterias] = useState([]);

  const {isOpen, onOpen, onClose} = useDisclosure();

  const {
    isOpen: isOpenQualityControl,
    onOpen: onOpenQualityControl,
    onClose: onCloseQualityControl,
  } = useDisclosure();

  const {
    isOpen: isOpenManagePictures,
    onOpen: onOpenManagePictures,
    onClose: onCloseManagePictures,
  } = useDisclosure();

  const textColor = useColorModeValue('gray.700', 'white.300');
  const toast = useToast();

  const [
    {
      loading: updateProjectPicturesInProgress,
      error: updateProjectPicturesError,
    },
    updateProjectPictures,
  ] = useUpdateProjectPictures(projectId);

  const [
    {
      loading: finishEditingInProgress,
      error: finishEditingError,
    },
    finishEditing,
  ] = useFinishEditing(projectId);

  const [
    {
      data: readyReport,
      loading: getReadyReportInProgress,
      error: getReadyReportError,
    },
    getReadyReport,
  ] = useGetReadyReport(projectId);

  const [
    {
      data: projectData,
      loading: getProjectInProgress,
      error: getProjectError,
    },
    getProjectAgain,
  ] = useGetProject(projectId);

  const openFinishModal = useCallback(() => {
    getReadyReport()
      .then(onOpen)
      .catch((e) => {
        console.error(e);
        // doesn't matter
      });
  }, [getReadyReport, onOpen]);

  const openManageModal = useCallback(() => {
    getReadyReport()
      .then(onOpenManagePictures)
      .catch((e) => {
        console.error(e);
        Bugsnag.notify(e);
      });
  }, [getReadyReport, onOpenManagePictures]);

  const handleFinishEditing = async () => {
    try {
      await finishEditing({data: {markedCriterias: selectedCriterias}});
      await getProjectAgain();
      onClose();
    } catch (error) {
      console.error('Error during finishing editing:', error);
    }
  };

  const handleFinishManagePictures = async () => {
    try {
      await updateProjectPictures();
      await getProjectAgain();
      await getReadyReport();
      onCloseManagePictures();
    } catch (error) {
      console.error('Error during manage pictures:', error);
    }
  };

  useEffect(() => {
    if (getProjectError) {
      console.error('Error during loading project data:', getProjectError);
      toast({
        title: 'Cannot load project data',
        status: 'error',
        duration: null,
        isClosable: true,
      });
    }
    if (finishEditingError) {
      console.error('Error during finishing editing:', finishEditingError);
      toast({
        title: 'Cannot finish editing',
        status: 'error',
        duration: null,
        isClosable: true,
      });
    }
    if (updateProjectPicturesError) {
      console.error('Error during manage pictures:', updateProjectPicturesError);
      toast({
        title: 'Cannot update project',
        status: 'error',
        duration: null,
        isClosable: true,
      });
    }
  }, [getProjectError, finishEditingError, updateProjectPicturesError]);

  const criteriaExists = !!projectData?.qualityCriterias?.length;

  return <>
    {
      !projectData
      && <SpinnerCurtains />
    }
    {
      projectData?.projectName
      && <Flex justifyContent="space-between" alignItems='center' mx='20px'>
        <Text as='b' fontSize='3xl'>Project: {projectData.projectName}</Text>
        <Flex direction='column' gap='0.5em'>
          <Button
            variant="photonMedium"
            leftIcon={<ArrowBackIcon />}
            width='15em'
            onClick={() => navigate('/intel/projects')}
          >
            Back
          </Button>
          {
            projectData.status === ProjectStatus.POST_PROCESSING
            && <Tooltip
              hasArrow
              label={
                getReadyReportError
                  ? 'Error during checking'
                  : getReadyReportInProgress
                    ? 'Checking...'
                    : readyReport.editedPicNumbers > 0
                      ? 'Ready to finish editing'
                      : 'Please check uploaded files.'
              }
            >
              <Button
                rightIcon={<ArrowForwardIcon/>}
                isLoading={getReadyReportInProgress}
                loadingText='Checking...'
                variant="photonLight"
                width='15em'
                onClick={openFinishModal}
              >
                Finish editing
              </Button>
            </Tooltip>
          }
          {
            projectData?.driveLink
            && <Link isExternal href={projectData.driveLink}>
              <Button variant='photonDark' width='15em'>
                Go to Drive folder
              </Button>
            </Link>
          }
        </Flex>
      </Flex>
    }
    <Box fontSize='20px'>
      {
        projectData
        && <Box>
          <Flex justifyContent='space-between' alignItems='center' ml='10px' mr='20px' direction='column'>
            <Flex w='100%' direction={{base: 'column', md: 'row'}}>
              <Flex
                justifyContent='center'
                p='10px 50px'
                alignItems='center' bg='white' borderRadius='20px' m='10px' w={{base: '100%', md: '50%'}}
              >
                <Text>{`Status: ${translateProjectStatus(projectData.status)}`}</Text>
              </Flex>
              <Flex
                justifyContent='center'
                textAlign='center'
                alignItems='center'
                bg='white'
                p='10px 50px'
                borderRadius='20px'
                m='10px'
                w={{base: '100%', md: '50%'}}
              >
                <Text>
                  {
                    projectData.editor === userId
                      ? 'You are the editor of this project'
                      : (
                        !projectData.editor
                          ? 'Open'
                          : 'Someone else is the editor of this project'
                      )
                  }
                </Text>
              </Flex>
            </Flex>

            <Flex w='100%' direction={{base: 'column', md: 'row'}}>
              <Flex
                justifyContent='center'
                alignItems='center'
                bg='white'
                p='10px 50px'
                borderRadius='20px' m='10px'
                w={{base: '100%', md: '50%'}}
              >
                <Text>{`Client name: ${projectData.clientName}`}</Text>
              </Flex>
              <Flex
                justifyContent='center'
                alignItems='center' bg='white' borderRadius='20px' m='10px'
                p='10px 50px'
                w={{base: '100%', md: '50%'}}
              >
                <Text align='center'>{`Package name: ${projectData.packageName}`}</Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex>
            <Flex
              bg='white'
              justifyContent='space-between'
              alignItems='center'
              height={{base: '200px', md: '50px'}}
              borderRadius='20px'
              p='10px' m='20px' w='100%'
              direction={{base: 'column', md: 'row'}}
            >
              <Text fontSize='20px' fontWeight='bold'>Photographer:</Text>
              <Flex><AiOutlineUser size='30px'/><Text ml='10px'> {projectData.creative.name}</Text></Flex>
              <Flex><AiOutlinePhone size='30px'/><Text ml='10px'> {projectData.creative.phone}</Text> </Flex>
              <Flex> <MdAlternateEmail size='30px'/><Text ml='10px'>{projectData.creative.email}</Text></Flex>
            </Flex>
          </Flex>

          {
            projectData.editable
            && <Box m='20px' position='relative'>
              <Text pos='absolute' m='0.5em' fontSize='20px' fontWeight='bold'>
                Edited images:
              </Text>
              <Flex direction='column' justifyContent='center' alignItems='center' bg='white' p='10px' borderRadius='20px'>
                <Text fontWeight='bold' sx={{py: '1em'}}>
                  You've uploaded {readyReport?.editedPicNumbers ?? '-'} images.
                </Text>
                <Button
                  width='fit-content'
                  variant='photonDark'
                  onClick={openManageModal}
                >
                  {
                    readyReport?.editedPicNumbers
                      ? 'Manage Images'
                      : 'Upload'
                  }
                </Button>
              </Flex>
            </Box>
          }

          {
            !projectData.editable
            && projectData.manualEditingStatus === ManualEditingStatus.EDITING_FINISHED
            && <FinalImagesList projectId={projectId}/>
          }

          <Flex>
            <Box w='40%'>
              <Flex justifyContent='center' alignItems='center' bg='white' p='10px' borderRadius='20px' m='20px'>
                <Link isExternal href={projectData.brief}>
                  <Text mr='10px'>
                    Click here to read project brief
                  </Text>
                </Link>
                <PiCursor/>
              </Flex>
              {
                projectData.refFiles?.length > 0
                && <Card shadow='md' borderWidth='1px' m='20px' p='10px'>
                  <Text textAlign='center' fontSize='20px' fontWeight='bold'>{`Reference files:`}</Text>
                  {
                    projectData.refFiles.map(
                      (file: RefFile) => <Flex key={file.url}>
                        <PiCursor/>
                        <Link href={file.url} isExternal> {file.name} </Link>
                      </Flex>,
                    )
                  }
                </Card>
              }
              <Card shadow='md' borderWidth='1px' m='20px' p='5px'>
                <Table size="sm" variant="simple">
                  <Tbody>
                    <Tr>
                      <Th fontSize="16px">Created</Th>
                      <Td fontSize="20px" lineHeight='25px'>
                        {moment(projectData.createdAt).format('YYYY.MM.DD.\n HH:mm')}
                      </Td>
                    </Tr>
                    <Tr borderTop='solid lightgrey'>
                      <Th fontSize="16px">Shooting time</Th>
                      <Td fontSize="20px" lineHeight='25px'>
                        {moment(projectData.shootingTime).format('YYYY.MM.DD.\n HH:mm')}
                      </Td>
                    </Tr>
                    <Tr borderTop='solid lightgrey'>
                      <Th fontSize="16px">Delivery (editing) deadline</Th>
                      <Td fontSize="20px" lineHeight='25px'>
                        {moment(projectData.deliveryDeadline).format('YYYY.MM.DD.\n HH:mm')}
                      </Td>
                    </Tr>
                    <Tr borderTop='solid lightgrey'>
                      <Th fontSize="16px">Photographer's deadline</Th>
                      <Td fontSize="20px" width='150px' lineHeight='25px'>
                        {moment(projectData.editingDeadline).format('YYYY.MM.DD.\n HH:mm')}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Card>

              <Card shadow='md' borderWidth='1px' m='20px' p='5px'>
                <Table size="sm" variant="simple">
                  <Tbody>
                    <Tr>
                      <Th fontSize={{base: '14px', md: '16px'}} padding='10px 15px'>Exp. number of photos</Th>
                      <Td fontSize="20px" textAlign='center'>{projectData.expectedPhotoNumber || 0}</Td>
                    </Tr>
                    <Tr borderTop='solid lightgrey'>
                      <Th fontSize={{base: '14px', md: '16px'}} padding='10px 15px'>Pics uploaded by photographer</Th>
                      <Td fontSize="20px" textAlign='center'>{projectData.uploadedPhotosNumber}</Td>
                    </Tr>
                    <Tr borderTop='solid lightgrey'>
                      <Th fontSize={{base: '14px', md: '16px'}} padding='10px 15px'>Pics uploaded by me</Th>
                      <Td fontSize="20px" textAlign='center'>{projectData.uploadedPhotosByEditorNumber}</Td>
                    </Tr>
                    <Tr borderTop='solid lightgrey'>
                      <Th fontSize={{base: '14px', md: '16px'}} padding='10px 15px'>Editing price</Th>
                      <Td w='150px' fontSize="20px" textAlign='center'>
                        {`${projectData.manualEditingPriceSum ?? '-'} ${projectData.manualEditingPrice?.currency ?? ''}`}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Card>

              <Flex direction='column' m='20px'/>

            </Box>

            {
              projectData.refPics?.length > 0
              && <Card w={{base: '100%', md: '60%'}}>
                <Text fontWeight='bold'>Reference photos:</Text>
                <Grid templateColumns="repeat(2, 1fr)" gap="5px" mt="10px">
                  {
                    projectData.refPics.map(
                      (file: {id: string, url: string, name: string}) => <Flex key={file.id} direction="column" alignItems="center">
                        <Image src={file.url} alt={file.name} maxH="200px"/>
                      </Flex>,
                    )
                  }
                </Grid>
              </Card>
            }
          </Flex>
          {
            projectData.comment
            && <Card w='100%'>
              <Text fontWeight='bold'>Comment:</Text>
              <Divider/>
              <Text textAlign='justify'>{projectData.comment}</Text>
            </Card>
          }
          {
            projectData.creativeMessage
            && <Card w='100%'>
              <Text fontWeight='bold'>Photographer's message:</Text>
              <Divider/>
              <Text textAlign='justify'>{projectData.creativeMessage}</Text>
            </Card>
          }
        </Box>
      }
      {
        getProjectInProgress
        && <Spinner/>
      }

      {/* error during loading data*/}
      {
        getProjectError
        && <Text color={textColor}>Error fetching data</Text>
      }

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Have you finished editing this project?</ModalHeader>
          <ModalCloseButton/>
          {
            readyReport
            && <ModalBody>
              <Text>
                You've uploaded {readyReport?.editedPicNumbers ?? '-'} images.
              </Text>
              <Text as='b'>Please confirm you are completing this project!</Text>
            </ModalBody>
          }
          <ModalFooter>
            <Button
              variant='photonMedium'
              mr={3} onClick={onClose}>
              Back
            </Button>
            <Button
              width='fit-content'
              isLoading={finishEditingInProgress}
              variant='photonDark'
              onClick={criteriaExists ? onOpenQualityControl : handleFinishEditing}
            >
              {criteriaExists ? 'Continue' : 'Close this project'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <QualityControlModal
        selectedCriterias={selectedCriterias}
        setSelectedCriterias={setSelectedCriterias}
        isOpen={isOpenQualityControl}
        onClose={onCloseQualityControl}
        handleFinishEditing={handleFinishEditing}
        qualityCriterias={projectData?.qualityCriterias}
      />

      <ManagePicturesModal
        isOpenManagePictures={isOpenManagePictures}
        onCloseManagePictures={onCloseManagePictures}
        getReadyReportInProgress={getReadyReportInProgress}
        readyReport={readyReport}
        projectId={projectId}
        updateProjectPicturesInProgress={updateProjectPicturesInProgress}
        handleFinishManagePictures={handleFinishManagePictures}
        useGetFolderList={useGetFolderList}
      />
    </Box>
  </>;
}
