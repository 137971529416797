import useAxios from './use-axios';
import serverClient from './serverClient';
import
axios,
{
  AxiosProgressEvent,
  AxiosResponse,
  CancelTokenSource,
} from 'axios';
import {UseAxiosResult} from 'axios-hooks';


export interface EditorApiError {
  error_message?: string;
}

export const useGetOngoingProjects = () => useAxios(
  {
    url: '/ongoing-projects',
    method: 'GET',
  },
);

export const useGetClosedProjects = () => useAxios(
  {
    url: '/closed-projects',
    method: 'GET',
  },
);

export interface RefFile {
  name: string;
  url: string;
}

export interface Price {
  amount: number;
  currency: string;
}

export interface RefPhoto {
  id: string;
  name: string;
  url: string;
}

export interface QualityCriteria {
  _id: string;
  name: string;
}

export interface Project {
  projectName: string;
  status: string;
  isOpen: boolean;
  clientName: string;
  createdAt: Date;
  shootingTime: Date;
  deliveryDeadline: Date;
  editingDeadline: Date;
  creative: {
    name: string;
    email: string;
    phone: string;
  };
  uploadedPhotosNumber: number;
  manualEditingPrice: Price;
  manualEditingPriceSum: number;
  brief: string;
  comment: string;
  refPics: RefPhoto[];
  refFiles: RefFile[];
  uploadedPhotosByEditorNumber: number;
  driveLink: string;
  editor?: string;
  manualEditingStatus: string;
  packageName: string;
  creativeMessage?: string;
  expectedPhotoNumber?: number;
  editorBrief: string;
  editable: boolean;
  qualityCriterias: QualityCriteria[]
}

export const useGetProject = (projectId: string): UseAxiosResult<Project, void, EditorApiError> => useAxios(
  {url: `/project/${projectId}`},
);

export const useDelPhoto = (projectId: string, photoId: string) => useAxios(
  {
    url: `/project/${projectId}/photo/${photoId}`,
    method: 'DELETE',
  },
  {manual: true},
);

export const delPhoto = (projectId: string, photoId: string): Promise<AxiosResponse> => serverClient()
  .delete(`/project/${projectId}/photo/${photoId}`);

export const uploadPhoto = (
  image: File,
  projectId: string,
  // eslint-disable-next-line
  setProgress: Function = null,
  delay: number = 0,
): [Promise<AxiosResponse>, CancelTokenSource] => {
  const formData = new FormData();
  formData.append('file', image);

  const source = axios.CancelToken.source();
  const config = {
    params: {},
    cancelToken: source.token,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => setProgress && setProgress(
      Math.min(
        Math.round((100 * progressEvent.loaded) / progressEvent.total),
        100,
      ),
    ),
  };

  return [
    new Promise<void>((resolve) => delay && setTimeout(resolve, delay * 1000) || resolve())
      .then(
        async () => await serverClient()
          .post(
            `/project/${projectId}/photo`,
            formData,
            config,
          ),
      ),
    source,
  ];
};

export const useUpdateProjectPictures = (projectId: string) => useAxios(
  {
    url: `/project/${projectId}/updatePictures`,
    method: 'POST',
  },
  {manual: true},
);

export const useFinishEditing = (projectId: string) => useAxios(
  {
    url: `/project/${projectId}/deliver`,
    method: 'POST',
  },
  {manual: true},
);

export const useGetFolderList = (projectId: string) => useAxios(
  {url: `/project/${projectId}/listFolder`},
);

export interface ReadyReport {
  editedPicNumbers: number;
  uploadedPicNumbers: number;
}

export const useGetReadyReport = (projectId: string): UseAxiosResult<ReadyReport, void, EditorApiError> => useAxios(
  {url: `/project/${projectId}/ready`},
);

export const useApplyEditing = (projectId: string) => useAxios(
  {
    url: `/project/${projectId}/apply`,
    method: 'POST',
  },
  {manual: true},
);
